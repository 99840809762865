<template>
  <div class="home">
    <div class="steps">
      <van-steps :active="active" active-icon="edit" active-color="#E24709">
        <van-step>发起理赔</van-step>
        <van-step>在线审核</van-step>
        <van-step>赔款给付</van-step>
      </van-steps>
    </div>
    <div class="form">
      <van-form>
        <van-field
          readonly
          clickable
          name="picker"
          :value="claimsType"
          label="理赔类型"
          placeholder="选择理赔类型"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-field
          v-if="detail.policy"
          label="被保险人"
          :value="`${detail.policy.contactsName} - ${detail.policy.contactsCard}`"
          readonly
        />
        <van-field
          v-if="detail.policy"
          v-model="detail.policy.contactsMobile"
          name="手机号码"
          label="手机号码"
          placeholder="填写手机号码"
        />
        <van-field
          v-if="detail.policy"
          label="收款账号"
          :value="`${detail.policy.bankName} - ${detail.policy.bankCard}`"
          readonly
        />
      </van-form>
    </div>
    <div class="formImg">
      <p>上传理赔凭证</p>
      <van-uploader v-model="claimsFiles" multiple :max-count="6" />
    </div>
    <div style="padding: 20px">
      <van-button
        round
        block
        type="info"
        color="#E24709"
        @click="submit"
        native-type="submit"
        >提交</van-button
      >
    </div>
  </div>
</template>

<script>
import { supportConceptDetail, saveClaims } from "../utils/api";
export default {
  data() {
    return {
      active: 0,
      claimsType: "",
      columns: [
        {
          text: "医疗",
          children: [
            {
              text: "门急诊",
            },
            {
              text: "住院"
            },
            {
              text: "生育"
            },
          ],
        },
        {
          text: "人身",
          children: [
             {
              text: "意外",
            },
            {
              text: "定期寿险"
            },
          ],
        },
      ],
      showPicker: false,
      username: "",
      uploader: [{ url: "https://img.yzcdn.cn/vant/leaf.jpg" }],
      claimsFiles: [],
      detail: {},
    };
  },
  created() {},
  methods: {
    //提交
    submit() {
      if (!this.claimsType) {
        this.$toast("请选择理赔类型");
      } else if (!this.detail.policy.contactsMobile) {
        this.$toast("请填写手机号");
      } else if (!/^1[0-9]\d{9}$/.test(this.detail.policy.contactsMobile)) {
        this.$toast("手机号格式不正确");
      } else if (this.claimsFiles.length === 0) {
        this.$toast("请上传理赔凭证");
      } else {
        let form_data = new FormData();
        form_data.append("claimsType", this.claimsType);
        form_data.append("contactsMobile", this.detail.policy.contactsMobile);
        form_data.append("policyId", this.detail.policy.policyId);
        this.claimsFiles.map((item) => {
          form_data.append("claimsFiles", item.file);
        });
        this.saveClaims(form_data);
      }
    },
    //保存理赔单
    async saveClaims(params) {
      this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
        duration: 0,
      });
      await saveClaims(params).then(() => {
        this.$toast.success("提交成功");
        this.$router.push({
          path: "/claimList",
        });
      });
    },
    //保单数据
    async supportConceptDetail() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await supportConceptDetail().then((res) => {
        this.detail = res.data;
      });
    },
    onConfirm(value) {
      console.log(value)
      this.claimsType = value.toString();
      this.showPicker = false;
    },
  },
  async mounted() {
    await this.supportConceptDetail();
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100vh;
  background: #f2f2f2;
  .steps {
    width: 100%;
    padding: 10px 10px;
    background: #fff;
  }
  .form {
    background: #fff;
    margin-top: 20px;
    width: 100%;
  }
  .formImg {
    background: #fff;
    margin-top: 20px;
    width: 100%;
    padding: 15px;
    p {
      font-size: 14px;
      line-height: 14px;
      color: #646566;
      padding-bottom: 20px;
    }
  }
}
</style>